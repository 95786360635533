import React, { Component } from "react";
import Header from "../../components/header/Header.js";
import Footer from "../../components/footer/Footer.js";
import TopButton from "../../components/topButton/TopButton.js";
import { marked } from 'marked';
import "./blogComponent.css";
import { contactPageData } from "../../portfolio.js";
import { Fade } from "react-reveal";

const blogSection = contactPageData.blogSection;

class Myblog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markdownFiles: [
        { fileName: './blog-posts/post1.md', displayName: 'Installing CEPH IN UBUNTU 20 LTS ' },
        { fileName: './blog-posts/post2.md', displayName: 'Ubuntu_Mac_File_Transfer' },
        { fileName: './blog-posts/raycast.md', displayName: 'Raycast: A much much better alternative to Spotlight' },
        { fileName: './blog-posts/starbucks.md', displayName: 'Evaluating Starbucks Promotional Offers-Capstone-Challenge' },
        { fileName: './blog-posts/transcript_summarizer.md', displayName: 'GPT Based Audio Summarizer' },
        { fileName: './blog-posts/perplexityGoogle.md', displayName: '"Battle of Search Engines"' },
        { fileName: './blog-posts/zed_test.md', displayName: '"GPT Powered Code Editor: Zed"' },
      ],
      currentMarkdown: '',
      isListVisible: true,
      activePostIndex: null,
    };
  }

  toggleListVisibility = () => {
    this.setState(prevState => ({
      isListVisible: !prevState.isListVisible
    }));
  }

  fetchMarkdownContent = (fileName,index) => {
    fetch(`/${fileName}`)
      .then(response => response.text())
      .then(text => this.setState({ currentMarkdown: text,activePostIndex:index }))
      .catch(error => console.error('Error fetching Markdown content:', error));
  }

  convertMarkdownToHtml = (markdown) => {
    return { __html: marked(markdown) };
  };

  render() {
    const { markdownFiles, currentMarkdown, isListVisible } = this.state;
    const theme = this.props.theme;

    return (
      <div className="blog-main">
        <Header theme={theme} />
        <Fade bottom duration={1000} distance="40px">
          <div className="blog-container">
            <div className="blog-heading">
              <h1 className="blog-title" style={{ color: theme.text }}>
                {blogSection["title"]}
              </h1>
              <button onClick={this.toggleListVisibility} className="toggle-list-button">
                {isListVisible ? 'Hide Posts' : 'Show Posts'}
              </button>
              {isListVisible && (
                <div className="blog-list">
                  <ul>
                    {markdownFiles.map((file, index) => (
                      <li key={index} onClick={() => this.fetchMarkdownContent(file.fileName,index)}
                      className={this.state.activePostIndex === index ? 'active-post' : ''}
                      >
                        {file.displayName}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="blog-content" dangerouslySetInnerHTML={this.convertMarkdownToHtml(currentMarkdown)} />
          </div>
        </Fade>
        <TopButton theme={theme} />
      </div>
    );
  }
}

export default Myblog;

